<template>
  <b-modal class="modal" :visible="show" rounded-0 centered hide-header-close id="modal-center" @hide="hide" size="md"
    :hide-footer="true" :hide-header="true">
    <div>
      <p class="title">{{ title }}</p>
      <p class="content">{{ message }}</p>
    </div>
    <div class="center">
      <button class="bike-btn" :disabled="loading" @click.prevent="hide">
        <span class="text">Tente novamente</span>
      </button>
    </div>

  </b-modal>
</template>
<script>
export default {
  props: ["show", "message", "title"],
  data() {
    return {
      loading: false,
    };
  },
  watch: {},
  methods: {
    hide() {
      this.$emit("hide");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open%20Sans");

.center {
  text-align: center;
}

.title {
  color: #78849E;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.content {
  text-align: center;
  color: #78849E;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bike-btn {
  /* display: block; */
  padding: 10px;
  width: 60%;
  height: 64px;
  border: 1px solid #263238;
  border-radius: 12px;
  background: #263238;
  color: #fff;
}
</style>
